import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/paymentPlan";

export async function searchPaymentPlans(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addPaymentPlan(paymentPlan) {
  return await ApiService.post(url, paymentPlan as any).then((res) => {
    const data = res.data;
    return data;
  }).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function updatePaymentPlan(paymentPlan) {
  if (paymentPlan) {
    return await ApiService.put(url + "/" + paymentPlan.id, paymentPlan as any)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function getPaymentPlan(id) {
  return await ApiService.get(url + "/" + id)
    .then((res) => {
      const paymentPlan = res.data;
      return paymentPlan;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}